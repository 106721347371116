import { axiosInstance } from "./axios";
import { RequestEnum } from "@/enums/httpEnum";

const request = (config) => {
  const {url, method, params, data, responseType, headers } = config
  return axiosInstance({
      url,
      method,
      params,
      data,
      responseType,
      headers
  }).then(res => res?.data)
}

export default {
  get:(url, params = {}, config = {}) => {
    return request({...config, url, params, method: RequestEnum.GET })
  },
  post:(url, data, config = {}) => {
    return request({...config, url, data, method: RequestEnum.POST })
  },
  put:(url, data, config = {}) => {
    return request({...config, url, data, method: RequestEnum.PUT })
  },
  patch:(url, data, config = {}) => {
    return request({...config, url, data, method: RequestEnum.PATCH })
  },
  delete:(url, params = {}, config = {}) => {
    return request({...config, url, params, method: RequestEnum.DELETE })
  },
}