import request from "@/utils/http"

export const getCurrencyExchangeRate = () => {
  return request.get(`assets/get_currency_exchange_rate`)
}

export const getAssets = () => {
  return request.get(`assets`)
}

export const getPersonalBalanceSheet = () => {
  return request.get(`assets/personal_balance_sheet`)
}

export const getAssetCharts = () => {
  return request.get(`assets/charts`)
}

export const getAssetChartsTotal = (type) => {
  return request.get(`assets/charts/total_assets/${type}`)
}

export const getAssetChartsComparedToLastWeek = () => {
  return request.get(`assets/charts/compared_to_last_week`)
}

export const getAsset = (id) => {
  return request.get(`assets/${id}`)
}

export const createAssets = (data) => {
  return request.post(`assets`, data)
}

export const deleteAssets = (id) => {
  return request.delete(`assets/${id}`)
}

export const updateAssets = (asset_id, data) => {
  return request.put(`assets/${asset_id}`, data)
}

export const updateSort = (data) => {
  return request.put(`assets/sort`, {sort_list:data})
}