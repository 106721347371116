import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { getLanguage } from '@/api/system/language'
import { reactive } from 'vue'

export const useLangStore = defineStore(
  'language',
  () => {
    const langArray = ref([])
    const langObj = ref({})
    const updatedAt = ref('2024-01-01 00:00:00')
    const currentLang = ref('jp')

    const languages = reactive([
      {
        flag: require('@/assets/images/flags/japan.png'),
        language: 'jp',
        title: '日本語'
      },
      {
        flag: require('@/assets/images/flags/us.jpg'),
        language: 'en',
        title: 'English'
      },
      {
        flag: require('@/assets/images/flags/china.png'),
        language: 'cn',
        title: '中文（繁體）'
      }
      // {
      //   flag: require("@/assets/images/flags/china.png"),
      //   language: "tw",
      //   title: "中文（繁體）"
      // }
    ])

    function setLang(lang) {
      currentLang.value = lang
    }

    function updateLang() {
      getLanguage(updatedAt.value).then((res) => {
        if (res?.data && res.data.length > 0) {
          langArray.value = res.data
          const obj = {}
          res.data.forEach((item) => {
            obj[item.key] = {
              en: item['en'],
              jp: item['jp'],
              cn: item['cn'],
              tw: item['tw']
            }
          })
          langObj.value = obj

          const date = dayjs().format('YYYY-MM-DD HH:mm:ss')
          updatedAt.value = date
        }
      })
    }

    const t = computed(() => (key) => {
      const lang = langObj.value[key]
      if (lang) {
        return lang[currentLang.value] || key
      }
      return key
    })

    const lang = computed(() => currentLang.value)

    function parseLang(lang) {
      const l = languages.find((l) => l.language === lang)
      if (l) {
        return l.title
      }
      return lang
    }

    return {
      langArray,
      langObj,
      updatedAt,
      currentLang,
      setLang,
      updateLang,
      t,
      lang,
      languages,
      parseLang
    }
  },
  {
    persist: true
  }
)
