export function parseAvatar(url) {
  if(!url){
    return "/default_avatar.jpg"
  }
  if(url.toLowerCase().includes("http")){
    return url
  }
  const base = process.env.VUE_APP_API_STATIC
  return `${base}${url}`
}

export function parseFileUrl(url) {
  if(!url){
    return
  }
  if(url.toLowerCase().includes("http")){
    return url
  }
  const base = process.env.VUE_APP_API_STATIC
  return `${base}${url}`
}

export function parseVideoUrl(url) {
  if(!url){
    return
  }
  if(url.includes('excel.videos.s3.ap-northeast-1')){
    return url.replace('excel.videos.s3.ap-northeast-1.amazonaws.com', 'videos.excel.jp')
  }
  if(url.toLowerCase().includes("http")){
    return url
  }
  const base = process.env.VUE_APP_API_STATIC
  return `${base}${url}`
}