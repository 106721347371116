import io from 'socket.io-client';
import { useUserStore } from "@/store/user"

var socketInstance = null

export function getSocket(){
  if(socketInstance){
    return socketInstance
  }
  const userStore = useUserStore()
  if(!userStore.token){
    return
  }
  const socket = io(process.env.VUE_APP_SOCKET_API, {
    auth:{
      token:userStore.token
    }
  });

  socket.on('connect', () => {
 
  });

  socket.on("disconnect", () => {
  
  });

  socketInstance = socket
  return socketInstance
}
