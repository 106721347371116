import request from "@/utils/http"

export const getNotifyByUser = () => {
  return request.get(`/notify/user`)
}

export const readNotice = (id) => {
  return request.put(`/notify/user/${id}`)
}

export const getNotifyByAdmin = () => {
  return request.get(`/notify/admin`)
}

export const getNotifyInfo = (id) => {
  return request.get(`/notify/admin/${id}`)
}

export const updateNotice = (id, data) => {
  return request.put(`/notify/admin/${id}`, data)
}
export const sendNotice = (id) => {
  return request.put(`/notify/admin/${id}/send`)
}

export const createNotice = (data) => {
  return request.post(`/notify/admin`, data)
}
export const deleteNotice = (id) => {
  return request.delete(`/notify/admin/${id}`)
}

export const getUsers = () => {
  return request.get(`/notify/admin/users`)
}