<template>
  <div id="app">
    <router-view />
    <template v-if="user.certified">
      <div class="chat-but" @click="showIframe = true" v-show="!showIframe">
        <i class="fas fa-ad fa-comment"></i>
      </div>
      <div class="iframe-container" :class="[fullscreen ? 'fullscreen' : '']" v-show="showIframe">
        <iframe :src="iframeUrl"></iframe>
        <div class="iframe-controller-box">
          <i class="bx bx-fullscreen" @click="changeFullscreen"></i>
          &nbsp;
          <i class="bx bx-x" @click="showIframe = false"></i>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { getSocket } from '@/socket'
import { ref } from 'vue'
import { onMounted } from 'vue'
import { useUserStore } from '@/store/user'
import { computed } from 'vue'

const user = useUserStore()

const iframeUrl = computed(() => `https://excel.jp/bot/?email=${user.email}`)
const showIframe = ref(false)
const fullscreen = ref(false)
function changeFullscreen() {
  fullscreen.value = !fullscreen.value
}
onMounted(() => {
  getSocket()
})
</script>

<style scoped lang="scss">
.fullscreen {
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.iframe-container {
  @media (max-width: 800px) {
    width: 100%;
    top: 0;
    height: 100%;
  }
  @media (min-width: 800px) {
    width: 600px;
    top: 70px;
    height: 96%;
  }
  box-shadow: 4px 4px 4px 4px #bfbfbf;
  z-index: 9999;
  position: fixed;
  right: 0;
  background-color: #fff;
  iframe {
    width: 100%;
    height: 100%;
  }
  .iframe-controller-box {
    position: absolute;
    right: 10px;
    top: 3px;
    gap: 10px;
    font-size: 18px;
    @media (max-width: 800px) {
      > i:first-child {
        display: none;
      }
    }
    i {
      cursor: pointer;
      box-shadow: 0px 0px 10px 1px #bfbfbf;
    }
  }
}
.chat-but {
  position: fixed;
  right: 60px;
  bottom: 60px;

  width: 40px;
  height: 40px;
  font-size: 20px;
  background-color: #5b73e8;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
}
</style>
