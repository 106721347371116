import request from "@/utils/http"

export const getLanguage = (timeStr) => {
  return request.get(`translate/init/${timeStr}`)
}


export const languageList = () => {
  return request.get(`translate`)
}

export const updateLanguage = (key, data) => {
  return request.put(`translate/${key}`, data)
}

export const deleteLanguage = (key) => {
  return request.delete(`translate/${key}`)
}

export const addLanguage = (key, data) => {
  return request.post(`translate/${key}`, data)
}