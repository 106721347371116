import appConfig from '../app.config.json'

export function redirectToAuthFinish(path) {
  path = path || appConfig.defaultRedirectUri
  window.location.href = `${appConfig.loginUrl}&client_id=${appConfig.clientId}&redirect_uri=${path}`
}

export function redirectToAuthFinishByNewTab(path) {
  path = path || appConfig.defaultRedirectUri
  const url = `${appConfig.loginUrl}&client_id=${appConfig.clientId}&redirect_uri=${path}`
  window.open(url, '_blank')
}

export function redirectToAuthFinishLogOut() {
  window.location.href = appConfig.defaultRedirectUri
  // path = path || appConfig.defaultRedirectUri
  // window.location.href = `${appConfig.loginUrl}&client_id=${appConfig.clientId}&redirect_uri=${path}&log_out=true`
}

export function tokenToInfo(token) {
  try {
    const strings = token.split('.')
    const userinfo = JSON.parse(
      decodeURIComponent(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/')))
    )
    return [userinfo['sub'], userinfo['cognito:username'], userinfo['email']]
  } catch (error) {
    console.error('tokenToId', error)
    return false
  }
}

export function extractIdToken(url) {
  if (!url || !url.includes('#')) {
    return false
  }
  try {
    url = `${window.location.origin}${url}`
    const parsedUrl = new URL(url)
    const fragment = parsedUrl.hash.substring(1)
    const params = new URLSearchParams(fragment)
    return params.get('id_token')
  } catch (error) {
    console.error('extractIdToken', error)
    return false
  }
}
