import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { login } from '@/api/user/auth'
import { useLangStore } from './language'
import { useAssetStore } from './asset'
import { parseAvatar } from '@/utils/file'
import { getSocket } from '@/socket'

export const useUserStore = defineStore(
  'excel_user',
  () => {
    const langStore = useLangStore()
    const assetStore = useAssetStore()

    const isAdmin = ref(false)
    const _token = ref(null)
    const user = ref({
      id: '',
      username: '',
      email: '',
      language: '',
      currency_unit: '',
      avatar: '',
      hobbies: ''
    })

    const userId = computed(() => user.value.id)
    const token = computed(() => _token.value)
    const username = computed(() => user.value.username)
    const certified = computed(() => _token.value !== null)
    const avatar = computed(() => parseAvatar(user.value.avatar))
    const email = computed(() => user.value.email)

    function setUser(userData) {
      user.value.id = userData.user_id
      user.value.username = userData.user_name
      user.value.email = userData.email
      user.value.language = userData.language
      user.value.currency_unit = userData.currency_unit
      user.value.avatar = userData.avatar
      user.value.hobbies = userData.hobbies
      isAdmin.value = userData?.is_admin || false

      assetStore.setCurrencyUnit(userData?.currency_unit || 'JPY')
      langStore.setLang(userData?.language || 'jp')
    }

    function userLogin(code, data) {
      if (certified.value) {
        return
      }
      return login(code, data).then((res) => {
        const userData = res.data
        _token.value = userData?.token
        setUser(userData)
        getSocket()
        return res
      })
    }

    function loginOut() {
      user.value.id = null
      user.value.username = null
      user.value.email = null
      _token.value = null
      window.localStorage.clear()
      localStorage.setItem('dashboard-intro', true)
    }

    function isShowMenu(menu) {
      if (menu.admin) {
        if (isAdmin.value) {
          return true
        }
        return false
      }
      return true
    }

    return {
      user,
      userId,
      token,
      _token,
      username,
      avatar,
      setUser,
      userLogin,
      certified,
      loginOut,
      isAdmin,
      isShowMenu,
      email
    }
  },
  {
    persist: true
  }
)
