import { defineStore } from 'pinia'
import { computed } from 'vue'
import { ref } from 'vue'
import { getNotifyByUser, readNotice } from '@/api/system/notify'
import { getSocket } from '@/socket'
import { useLangStore } from '@/store/language'

export const useNotifyStore = defineStore(
  'notify',
  () => {
    const notifyList = ref([])

    function initNotifyList() {
      getNotifyByUser().then((res) => {
        const data = res.data
        notifyList.value = data.map((v) => {
          return {
            id: v.id,
            title: v.notify_type + 'notify',
            content: v.notice_content,
            date: v.created_at,
            status: v.notice_status
          }
        })
      })
    }

    const isOnlyUnread = ref(true)

    const unreadNoticesCount = computed(() => notifyList.value.filter((v) => v.status === 0).length)

    const allNotifyList = computed(() => {
      if (isOnlyUnread.value) {
        return notifyList.value.filter((v) => v.status === 0)
      }
      return notifyList.value
    })

    function allRead() {
      for (const item of notifyList.value) {
        if (item.status === 0) {
          item.status = 1
          readNotice(item.id).then(() => {})
        }
      }
    }

    function read(id) {
      const notify = notifyList.value.find((v) => v.id === id)
      notify.status = 1
      readNotice(id).then(() => {})
    }

    function onSendDesktopNotifications(title, option) {
      if (Notification.permission === 'granted') {
        sendDesktopNotifications(title, option)
      } else if (Notification.permission === 'default') {
        Notification.requestPermission().then((result) => {
          if (result === 'granted') {
            sendDesktopNotifications(title, option)
          }
        })
      }
    }

    function sendDesktopNotifications(title, option) {
      const notification = new Notification(title, option)
    }

    const socket = getSocket()
    if (socket) {
      socket.on('notification', (v) => {
        notifyList.value.unshift({
          id: v.id,
          title: v.notify_type + 'notify',
          content: v.notice_content,
          date: v.created_at,
          status: v.notice_status
        })
        const lang = useLangStore()
        onSendDesktopNotifications(lang.t(v.notify_type + 'notify'), {
          body: v.notice_content
        })
      })
    }
    return {
      notifyList,
      unreadNoticesCount,
      allRead,
      isOnlyUnread,
      allNotifyList,
      read,
      initNotifyList,
      onSendDesktopNotifications
    }
  },
  {
    persist: true
  }
)
