import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { computed } from 'vue'
import { ref } from 'vue'
import { getCurrencyExchangeRate } from '@/api/asset/assets';
import BigNumber from "bignumber.js";


export const useAssetStore = defineStore("asset", () => {
  
  const _requestComplete = ref(false)
  const requestComplete = computed(() => _requestComplete.value)
  function changeRequestComplete(){
    _requestComplete.value = true
    setTimeout(() => {
      _requestComplete.value = false
    }, 500)
  }

  const currencyUnits = reactive([
    {name:'USD',sign:'$', value:'USD', unit:'K'},
    {name:'JPY',sign:'¥', value:'JPY', unit:'万円'},
    {name:'EUR',sign:'€', value:'EUR', unit:'K'},
  ])
  const currentCurrencyUnitName = ref('JPY')
  const currentCurrency = ref('JPY')
  const currentCurrencyUnitSign = ref('¥')
  const currentCurrencyUnit = ref('万円')

  function setCurrencyUnit(v){
    const unit = currencyUnits.find(u => u.value === v)
    currentCurrency.value = unit.value
    currentCurrencyUnitName.value = unit.name
    currentCurrencyUnitSign.value = unit.sign
    currentCurrencyUnit.value = unit.unit
  }

  const _currencyExchangeRateObj = ref({})
  function initCurrencyExchangeRate(){
    getCurrencyExchangeRate().then(res => {
      if(res.code === 20000){
        _currencyExchangeRateObj.value = res.data
      }
    })
  }

  /**
   * 汇率转换
   */
  const exchangeRate = computed(() => (value) => {
    if(currentCurrency.value === 'JPY'){
      return BigNumber(value).decimalPlaces(2, BigNumber.ROUND_DOWN).toNumber()
    }
    const usd = BigNumber(value * 10000).div(_currencyExchangeRateObj.value['JPY']).div(1000)
    if(currentCurrency.value === 'USD'){
      return usd.decimalPlaces(2, BigNumber.ROUND_DOWN).toNumber()
    }
    if(currentCurrency.value === 'EUR'){
      return usd.times(_currencyExchangeRateObj.value['EUR']).decimalPlaces(2, BigNumber.ROUND_DOWN).toNumber()
    }
    return value
  })

  return {
    requestComplete, 
    changeRequestComplete, 
    currencyUnits, 
    currentCurrency, 
    currentCurrencyUnit,
    currentCurrencyUnitName, 
    currentCurrencyUnitSign, 
    setCurrencyUnit, 
    exchangeRate, 
    initCurrencyExchangeRate
  }
},{
  persist: true
})