import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { vMaska } from 'maska'
import vClickOutside from 'v-click-outside'
import VueApexCharts from 'vue3-apexcharts'
import CKEditor from '@ckeditor/ckeditor5-vue'
import BootstrapVueNext from 'bootstrap-vue-next'
import { simplebar } from 'simplebar-vue'
import '@/assets/scss/app.scss'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'leaflet/dist/leaflet.css'
import '@vueform/multiselect/themes/default.css'
import '@vueform/slider/themes/default.css'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { createHead } from '@unhead/vue'
import vue3PhotoPreview from 'vue3-photo-preview'
import 'vue3-photo-preview/dist/index.css'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const toastOption = {
  transition: 'Vue-Toastification__fade',
  maxToasts: 5,
  newestOnTop: true,
  position: 'bottom-center',
  hideProgressBar: true,
  timeout: 3000,
  draggable: false,
  closeOnClick: false
}
import VueSocialSharing from 'vue-social-sharing'
const head = createHead()

createApp(App)
  .use(pinia)
  .use(Toast, toastOption)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVueNext)
  .directive('maska', vMaska)
  .use(CKEditor)
  .use(simplebar)
  .use(vClickOutside)
  .use(VueSocialSharing)
  .use(head)
  .use(vue3PhotoPreview)
  .mount('#app')
