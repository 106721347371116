import axios from 'axios'
import { config } from './config'
import { ResponseStatusEnum } from '@/enums/httpEnum'
import { useToast } from 'vue-toastification'
import { useUserStore } from '@/store/user'
import { redirectToAuthFinish } from '@/utils/auth'

const axiosInstance = axios.create(config)

axiosInstance.interceptors.request.use(
  (config) => {
    const userStore = useUserStore()
    config.headers.Authorization = userStore.token
    return config
  },
  (error) => {
    console.error('request error:', error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.code === ResponseStatusEnum.SUCCESS) {
      return response
    }
    if (response.data.code === ResponseStatusEnum.UNAUTHORIZED) {
      // const toast = useToast();
      const userStore = useUserStore()
      // toast.error(response.data.message)
      userStore.loginOut()
      redirectToAuthFinish()
      return
    }
    return response
  },
  (error) => {
    const toast = useToast()
    console.error('error:', error)
    toast.error(error.message)
  }
)

export { axiosInstance }
