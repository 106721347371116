import { createWebHistory, createRouter } from 'vue-router'
import routes from './routes'
import appConfig from '../app.config.json'
import { useUserStore } from '@/store/user'
import { useLangStore } from '@/store/language'
import { useAssetStore } from '@/store/asset'
import { useNotifyStore } from '@/store/notify'
import { extractIdToken, tokenToInfo } from '@/utils/auth'
import { redirectToAuthFinish, redirectToAuthFinishLogOut } from '@/utils/auth'
const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  }
})

var firstLoading = true

router.beforeResolve(async (routeTo, routeFrom, next) => {
  if (firstLoading) {
    const langStore = useLangStore()
    langStore.updateLang()
  }
  const userStore = useUserStore()
  if (routeTo.fullPath === '/logout') {
    userStore.loginOut()
    redirectToAuthFinishLogOut()
    return false
  }

  const idToken = extractIdToken(routeTo.href)
  if (idToken) {
    const info = tokenToInfo(idToken)
    if (!info) {
      return false
    }
    const [userId, name, email] = info
    await userStore.userLogin(userId, { name, email })
  }

  if (!userStore.certified && routeTo.meta.authRequired) {
    redirectToAuthFinish()
    return false
  }

  if (firstLoading && userStore.certified) {
    const assetStore = useAssetStore()
    const notifyStore = useNotifyStore()
    assetStore.initCurrencyExchangeRate()
    notifyStore.initNotifyList()
  }
  firstLoading = false

  document.title = routeTo.meta.title + ' | ' + appConfig.title

  next()
})

export default router
